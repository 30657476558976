<template>
<div class="apCodeUpload-page-content winner-page__hero">
   <div class="main-block position-relative">
      <div class="container-xl pt-lg-5">
        <div class="row  pt-lg-5">
            <div class="col-12  pt-lg-5">
                <div class="winner-bg text-center text-lg-start pt-lg-5">
                    <div class="row">   
                        <div class="col-12 col-md-5 position-relative winner-page__winning order-1 order-md-0">
                            <img v-if="winnerType == 'daily'" src="@/assets/imgs/winnings-daily.png" alt="napi nyememény" width="80%" class="img-fluid mb-2"/>
                            <img v-if="winnerType == 'weekly'" src="@/assets/imgs/winnings-weekly.png" alt="heti nyeremény" width="80%" class="img-fluid"/>
                            <img v-if="winnerType == 'main'" src="@/assets/imgs/winnings-main.png" class="img-fluid me-3" width="80%">  
                            <img v-if="winnerType == 'extra_kolorado'" src="@/assets/imgs/winnings-kolorado.png" class="img-fluid me-3" width="80%"> 
                            <img v-if="winnerType == 'extra_forma_1'" src="@/assets/imgs/winnings-extra_forma.png" class="img-fluid me-3" width="80%"> 
                            <img v-if="winnerType == 'extra_balaton_sound'" src="@/assets/imgs/winnings-extra_sound.png" class="img-fluid me-3" width="80%">          
                            <a href="#winnerFormWrap" class="btn btn-secondary d-inline-flex d-md-none">Kérem a nyereményem</a>
                            <p class="font-secondary text-primary mt-5 d-block d-md-none" style="font-size:18px;line-height:22px;">A részletekkel kapcsolatban email-ben fogunk keresni.<br>Részletes információk a játékszabályzatban.<br>A képek illusztrációk.</p>                 
                        </div>
                        <div class="col-12 col-xl-5 col-md-7 d-md-flex justify-content-center flex-column order-0 order-md-1">
                            <div class="">
                                <h1 class="text-white text-uppercase fw-900 mb-3">
                                    Gratulálunk!
                                </h1>  
                                <p class="fs-24 fw-700 text-white font-primary text-left subtitle mb-0 mb-lg-2">Érvényes pályázat esetén megnyerted</p>            
                                <p class="text-white pb-1 pt-2 mb-0 fs-35 fw-700 winner-page__hero__winning mb-3">
                                    <span v-if="winnerType == 'daily'"><span class="">napi nyereményünket</span></span>
                                    <span v-if="winnerType == 'weekly'"><span class="">heti nyereményünket</span></span>
                                    <span v-if="winnerType == 'main'"><span class="text-uppercase">fődíjunkat!</span></span>
                                    <span v-if="winnerType == 'extra_kolorado'"><span class="">extra nyereményünket!</span></span>
                                    <span v-if="winnerType == 'extra_forma_1'"><span class="">extra nyereményünket!</span></span>
                                    <span v-if="winnerType == 'extra_balaton_sound'"><span class="">extra nyereményünket!</span></span>
                                </p>
                                <a href="#winnerFormWrap" class="btn btn-secondary d-md-inline-flex d-none">Kérem a nyereményem</a>
                                <p class="font-secondary text-white mt-5 d-none d-md-block" style="font-size:18px;line-height:22px;">A részletekkel kapcsolatban email-ben fogunk keresni.<br><br>Részletes információk a játékszabályzatban.<br>A képek illusztrációk.</p>
                            </div>
                        </div>   
                    </div>
                </div>
            </div>
        </div>   
    </div>    
  </div>
  <div class="py-3 text-center text-white" style="background-color: #064D2B;">                               
        <p class="mb-0 font-secondary fs-16 px-3" style="line-height:25px;">A promóció időtartama: 2024. 05. 16. - 07. 17. A promócióban csak 18 éven felüliek játszhatnak.<br>Azért, hogy a nyereményeket biztosan át tudjuk adni, kérjük, hogy a kupakokat és a nyitófüleket a játék vége után 2 hónapig (2024. 09. 17-ig), illetve a nyeremények átadásáig őrizd meg!</p>
    </div> 
</div>
</template>

<script>

export default {
    components:{
        //Ribbon
    },
    props:['winnerType']
}
</script>