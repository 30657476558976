<template>
  <div class="winner-page__main-content ">
    <div class="email-info-message-background--padding">
      <div class="expired-container-wrap">
        <CodeFillingWinnerformHero :winnerType="winnerType"/>
        <!-- Már regisztrált -->
        <div
            class="expired-container second-background-inner--winner-messsage text-center form--status__content--expired block--big"
            v-if="alreadyRegistered"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12 col-lg-10 offset-lg-1 py-5">
                <div class="expired-container__inner py-5">
                  <div class="col-12 text-center mb-5" style="border-bottom: 1px solid #e7e5e5;">
                      <img src="@/assets/imgs/logo-form.svg" alt="Heineken" class="logo pb-5" />
                  </div>
                  <div class="main-h1 display-6 text-primary px-2 fw-700">
                    Ehhez a kódhoz már megadtad az adataidat!
                  </div>
                  <div class="codefilling-text pt-4">
                    <!--<div class="text-primary font-primary text-uppercase fs-24 fw-bold mt-4">
                      Mit kell tenned ezután?
                    </div>-->
                    <p class="text-black font-secondary mt-3">A játék ideje alatt növeld nyerési esélyedet és a tölts fel újabb kódokat!</p>
                  </div>
                  <div class="btn-wrap m-md-1">
                    <button class="btn btn-secondary mb-sm-5 mt-4 me-sm-4 btn-primary--large" @click="backToForm">
                      Újabb kódot töltök fel <img src="@/assets/imgs/arrow-circle.svg" class="img-fluid ms-2 mb-1">
                    </button>
                    <!--<button class="btn btn-primary mb-5 mt-4 btn-primary--large" @click="backToSendings">
                      Beküldéseim
                    </button>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Sikeres adatfeltöltés -->
        <div id="success-data-upload"
             class="expired-container second-background-inner  text-center form--status__content--expired block--big"
             v-if="!uploadedError && !alreadyRegistered && !formExpired && !formDisabled"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12 py-5">
                <div class="expired-container__inner py-5">
                    <div class="col-12 text-center mb-5" style="border-bottom: 1px solid #e7e5e5;">
                      <img src="@/assets/imgs/logo-form.svg" alt="Heineken" class="logo pb-5" />
                  </div>
                  <div class="main-h1 display-6 text-primary fw-700 pt-2 px-2">Sikeres adatfeltöltés</div>
                  <div class="codefilling-text">
                    <div class="font-primary fs-24 fw-bold mt-4" style="color:#064D2B;">
                      Mit kell tenned ezután?
                    </div>
                  </div>
                  <div class="text-container font-secondary fw-normal text-black py-1">
                      <p class="font-secondary">
                        Továbbra is őrizd a nyertes nyitófület a nyeremény átadásáig. Figyeld a postafiókodat (a promóciós üzeneteket és a spamet gyűjtő mappákat is), mert a nyereménnyel kapcsolatos további részletekről ott tájékoztatunk. <br><br><span class="text-primary fw-700">A játék ideje alatt növeld nyerési esélyedet és a tölts fel újabb kódokat!</span>
                      </p>
                  </div>
                  <div class="btn-wrap m-md-1">
                    <button class="btn btn-secondary mb-sm-5 btn-primary--large mt-4 me-sm-4" @click="backToForm">
                      Újabb kódot töltök fel  <img src="@/assets/imgs/arrow-circle.svg" class="img-fluid ms-2 mb-1">
                    </button>
                    <!--<button class="btn btn-primary mb-5 mt-4 btn-primary--large" @click="backToSendings">
                      Beküldéseim
                    </button>-->
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- lejárt az idő -->        
          <div
            class="expired-container second-background-inner  text-center form--status__content--expired block--big"
            v-if="formExpired"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12 py-5">
                <div class="expired-container__inner py-5">
                    <div class="col-12 text-center mb-5" style="border-bottom: 1px solid #e7e5e5;">
                      <img src="@/assets/imgs/logo-form.svg" alt="Heineken" class="logo pb-5" />
                  </div>
                  <div class="main-h1 display-6 text-red px-2 fw-900 pt-2">
                    A nyereményre való jogosultságodnak határideje lejárt
                  </div>                 
                  <div class="font-secondary pt-3 pb-3 text-container">
                    <p class="mt-4">Sajnos a Játékszabályzatban meghatározott visszajelzési határidő lejárt, ezért annak rendelkezései alapján pótnyertest sorsoltunk.</p>
                    <p class="form-expired-bold-text font-secondary fw-bold text-primary">De ne csüggedj, a nyereményjáték végéig, 2024. július 17-ig még van lehetőséged játszani.</p>
                  </div>
                  
                    <div class="btn-wrap">
                    <button class="btn btn-secondary mt-3 btn-primary--large me-sm-4" @click="backToForm">
                      Újabb kódot töltök fel <img src="@/assets/imgs/arrow-circle.svg" class="img-fluid ms-2 mb-1">
                    </button>
                   <!-- <button class="btn btn-primary mt-3 btn-primary--large" @click="backToSendings">
                      Beküldéseim
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Érvénytelen pályázat -->        
        <div
            class="expired-container second-background-inner  text-center form--status__content--expired block--big"
            v-if="formDisabled"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12 py-5">
                <div class="expired-container__inner py-5">
                    <div class="col-12 text-center mb-5" style="border-bottom: 1px solid #e7e5e5;">
                      <img src="@/assets/imgs/logo-form.svg" alt="Heineken" class="logo pb-5" />
                  </div>
                  <div class="main-h1 display-6 text-red fw-700 px-2 bg-red pt-2">A pályázatod az ellenőrzés során valamilyen okból nem felelt meg.</div>                  
                  <!--<div class="email-info-text--small text-container font-secondary">
                    A <a :href="`/pdf/Jatekszabalyzat.pdf?` + TimeStamp"
                         style="text-decoration:underline; color:black; font-weight:bold" target="_blank">Játékszabályzatban</a>
                    rendelkezései alapján pótnyertest sorsoltunk.<br/><br/>
                  </div>-->
                  <p class="font-black fw-700 text-container">
                    Kérjük, ellenőrizd postafiókod a további információkért.
                  </p>                  
                  <!--<div class="btn-wrap m-md-1">
                    <button class="btn btn-primary mt-3 btn-primary--large" @click="backToForm">
                      Újabb kódot töltök fel
                    </button>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CodeFillingWinnerformHero from '@/components/CodeFillingWinnerFormHero.vue'

export default {
  name: "FormExpiredAndRegistered",
  props: ['formExpired','alreadyRegistered', 'formDisabled', 'uploadedWinner', 'uploadedError', 'winnerType'],
    data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
    }
  },
  components:{
    CodeFillingWinnerformHero
  },
  methods: {
    backToForm() {
      this.$router.push("/#palyazat");
      window.scrollTo(0, 0);
    },
    backToSendings(){
      this.$router.push("/#bekuldeseim");
      window.scrollTo(0, 0);
    }
  }  
};
</script>
